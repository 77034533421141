import React, { useCallback } from 'react';
import styles from './styles.module.css';

const CustomCarouselDots = ({ activeSlideIndex, setActiveSlideIndex, data = [], inActiveDotStyle={} }) => {
  const handleDotClick = useCallback((e) => {
    const slideIndex = parseInt(e.target?.dataset?.slideindex);
    if (slideIndex >= 0 && slideIndex < data.length) setActiveSlideIndex(slideIndex);
  }, []);

  return (
    <div className="flex w-full gap-[5px] items-center absolute top-[calc(100%-15px)] z-2 justify-center" onClick={handleDotClick}>
      {
        <>
          {!(activeSlideIndex - 1 <= 0) && (
            <div className="flex w-[8px] h-[8px] items-center justify-center">
              <div data-slideIndex={`${activeSlideIndex - 2}`} style={inActiveDotStyle} className={`${styles.carouselDot}`} />
            </div>
          )}
          {!(activeSlideIndex <= 0) && (
            <div className="flex w-[8px] h-[8px] items-center justify-center">
              <div data-slideIndex={`${activeSlideIndex - 1}`} style={inActiveDotStyle} className={`${styles['carouselDot--active-siblings']} ${styles.carouselDot}`} />
            </div>
          )}
          <div className="flex w-[8px] h-[8px] items-center justify-center">
            <div data-slideIndex={`${activeSlideIndex}`} className={`${styles['carouselDot--active']} ${styles.carouselDot}`} />
          </div>
          {!(data.length - activeSlideIndex - 1 <= 0) && (
            <div className="flex w-[8px] h-[8px] items-center justify-center">
              <div data-slideIndex={`${activeSlideIndex + 1}`} style={inActiveDotStyle} className={`${styles['carouselDot--active-siblings']} ${styles.carouselDot}`} />
            </div>
          )}
          {!(data.length - activeSlideIndex - 2 <= 0) && (
            <div className="flex w-[8px] h-[8px] items-center justify-center">
              <div data-slideIndex={`${activeSlideIndex + 2}`} style={inActiveDotStyle} className={`${styles.carouselDot}`} />
            </div>
          )}
        </>
      }
    </div>
  );
};

export default CustomCarouselDots;
