import { useContext } from 'react';
import useTranslation from '@Utils/useTranslation';

import { getPriceRange } from '@Utils/common';
import { ProductCartType } from '@Constants/common';

import { CardDataProp } from './type';
import ProductCard, { getTrackingEventMetadata } from '../ProductCard';
import Link from 'next/link';
import { TrackerContext } from '_app'
import { useRouter } from 'next/router';
import { getTrackingEventObject } from '@Utils/common';
import { getImageWidth } from '@Reducers/ProductCard/utils';
import { RECOMMENDED_PRODUCT_EVENTS } from '@Constants/events';

type Params = {
  event_type: string;
  params: {
    sort_by?: string;
    category?: string;
    sub_category?: string;
    tag?: string;
  };
};

const getLink = (obj: Params) => {
  const { category, sub_category, tag } = obj?.params || {};
  let link = '';

  if (tag) link = `/collection/${tag}`;
  else if (sub_category || category)
    link = `/womens-collection/${sub_category || category}`;
  else link = `/shop`;

  return link;
};

const productCardListingStyles = {
  [ProductCartType.HOME]: {
    containerStyles: 'gap-[22px] pt-[17px] pb-[52px]',
    productWrapperStyles: 'gap-[14px] cursor-pointer',
  },
  [ProductCartType.RECOMMENDED_PRODUCTS]: {
    containerStyles: 'gap-[10px] pt-[10px]',
    productWrapperStyles: 'gap-[12px]',
  },
}

const getProductCardListingStyles = (cardType:string) => {
  switch(cardType){
    case ProductCartType.RECOMMENDED_PRODUCTS: 
      return productCardListingStyles[ProductCartType.RECOMMENDED_PRODUCTS];
    default: 
      return productCardListingStyles[ProductCartType.HOME];
  }
}

const getTrackingEventFeedType = (type: string, idx?: string| number) => {
  switch(type){
    case ProductCartType.RECOMMENDED_PRODUCTS:
      return RECOMMENDED_PRODUCT_EVENTS.FEED_TYPE;
    default: 
      return `home-page-card-listing-${idx}`;
  }
}

const ProductCardListings = ({
  cardData,
  onViewAllPress,
  linkData,
  type = ProductCartType.HOME,
  idx,
  handleAddToBag,
  disableDefaultSizeSelect = false,
  handleBottomSheetAddToCart,
}: {
  cardData: CardDataProp[];
  onViewAllPress: () => void;
  linkData: Params;
  type: string;
  idx: number;
  handleAddToBag: ()=>void,
  disableDefaultSizeSelect?: boolean;
  handleBottomSheetAddToCart?: (productId: number) => void ;
}) => {

  const router = useRouter(), 
    { t: translate } = useTranslation(),
   track = useContext(TrackerContext),
   { containerStyles, productWrapperStyles  } = getProductCardListingStyles(type),
   isHomeProductCardType = type===ProductCartType.HOME;

  return (
    <div className={'relative flex flex-col '+ containerStyles}>
      <div className={'flex overflow-auto hideScrollBar ' + productWrapperStyles}>
        {cardData.map((data, i) => {
          const { minSalePrice, regularPrice, discount, maxSalePrice, shouldShowPriceRange } = getPriceRange(
            data?.variations
          );
          const inStock = data?.variations?.some(item => item.stock_status === 'instock');
          const { track_data } = data ?? {};
          
          return (
            <ProductCard
              product={data}
              productId={data?.product_id}
              slug={data?.slug}
              key={data?.product_id}
              productImage={data?.image_url}
              discount={discount}
              oldPrice={regularPrice}
              minSalePrice={minSalePrice}
              maxSalePrice={maxSalePrice}
              shouldShowPriceRange={shouldShowPriceRange}
              sizeCardsData={data?.variations}
              showSizeCards={isHomeProductCardType}
              type={type}
              name={data?.name}
              showWishList={isHomeProductCardType}
              category={data?.category}
              productInfo={data?.product_info}
              styleId={data?.style_id}
              parent_sku={data?.parent_style_id}
              parent_id={data?.parent_id}
              rating={(type!==ProductCartType.RECOMMENDED_PRODUCTS) && data?.rating }
              productImagesSet={data?.image_gallery_urls || []}
              productImageStyle={type===ProductCartType.RECOMMENDED_PRODUCTS? {
                width: 135,
                aspectRatio: 0.7, 
                cursor: 'default', 
                objectFit: 'cover', 
                objectPosition: 'center' }: null}
              isTitleLink
              fastDeliveryText={data?.fast_delivery_text || ''}
              feedPosition={i}
              itemClick={()=>{
                track(getTrackingEventObject(i, getTrackingEventFeedType(type, idx), 'click', data?.product_id,router.asPath, getTrackingEventMetadata(type, data?.product_id, router, track_data ?? {})))
              }}
              onIntersection={() => {
                track(getTrackingEventObject(i, getTrackingEventFeedType(type, idx), 'scroll', data?.product_id,router.asPath , {  tagData: data?.display_tag ?? {}, ...getTrackingEventMetadata(type, data?.product_id, router, track_data ?? {})}))
              }}
              isLazyLoading
              tag={data?.display_tag}
              isInStock={inStock}
              onAddBagClick={handleAddToBag}
              disableDefaultSizeSelect={disableDefaultSizeSelect}
              handleBottomSheetAddToCart={handleBottomSheetAddToCart}
            />
          );
        })}
      </div>
      {linkData && <Link href={getLink(linkData) ?? ''} className='text-[14px] text-nm_primary_dark font-semibold border-[1px] border-solid border-nm_primary_dark rounded-[8px] flex justify-center py-[14px] mx-[16px] cursor-pointer ' onClick={onViewAllPress} prefetch={false}>
        {translate('view_all')}
      </Link>}
    </div>
  );
};

export default ProductCardListings;