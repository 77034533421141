import React, { useCallback } from 'react';
import BogoSaleEnds from '@assets/images/BogoSaleEnds.svg';
import { MAX_WIDTH } from '@Constants/common';
import { isEmptyObject } from '@Utils/checks';
import styles from './styles.module.css';

const outerContainerPadding = 16,
  timerContainerHorPadding = 12,
  defaultTimerUnitWidth = 40,
  seperatorWidth = 5;

const getTimerConfig = (titleType: string) => {
  switch (titleType) {
    case 'start':
      return {
        timerFontSize: 56,
        content: <div className="text-[24px] font-[700] leading-[120%]">Sale Starts in</div>
      };
    case 'end':
      return {
        timerFontSize: 60,
        content: <BogoSaleEnds className="text-center mx-[50px] my-[10px] max-w-[260px] w-full" />
      };
    default:
      return {
        timerFontSize: 60,
        content: <></>
      };
  }
};

const renderTimer = (time: string, width: number, charLength: number, isLoading?: boolean, separator?: boolean) => {
  return (
    <>
      <div className={'flex mx-[2px]' + (isLoading && styles.timer)} style={{ width: width * charLength }}>
        {time.split('').map((item) => (
          <div className={isLoading && styles.loadingTimer} style={{ minWidth: width }}>
            {item}
          </div>
        ))}
      </div>
      {separator && <span style={{ width: width / 2 }}>{!isLoading && ':'}</span>}
    </>
  );
};

const BogoCountdownTimer = ({ bogoSaleTimeLeft, bogoMetaData, isWebAppConfigLoading }) => {
  const { hours, minutes, seconds } = bogoSaleTimeLeft,
    { text, color, bg_color } = bogoMetaData ?? {};

  if ((isEmptyObject(bogoMetaData) || !hours || !minutes || !seconds || (hours === '00' && minutes === '00' && seconds === '00')) && !isWebAppConfigLoading)
    return;

  const getTimerCharacter = useCallback(
    (type: string) => {
      if (isWebAppConfigLoading) return '--';

      switch (type) {
        case 'hours':
          return hours;
        case 'mins':
          return minutes;
        case 'secs':
          return seconds;
        default:
          return '';
      }
    },
    [hours, minutes, seconds, isWebAppConfigLoading]
  );

  const totalHours = getTimerCharacter('hours'),
    numOfHourDigits = totalHours.split('').length,
    // Make timer width & size responsive based on screen width
    screenWidth = window.innerWidth,
    maxWidth = screenWidth > MAX_WIDTH ? MAX_WIDTH : screenWidth,
    availableContainerWidth = maxWidth - 2 * (outerContainerPadding + timerContainerHorPadding),
    totalWidth = availableContainerWidth / (numOfHourDigits + 4) - seperatorWidth,
    unitWidth = totalWidth > defaultTimerUnitWidth ? defaultTimerUnitWidth : totalWidth,
    computeFontSize = maxWidth / (numOfHourDigits + 4),
    { timerFontSize, content } = getTimerConfig(text),
    fontSize = computeFontSize > timerFontSize ? timerFontSize : computeFontSize;

  return (
    <div className="bg-nm_white" style={{ padding: outerContainerPadding }}>
      <div
        className="text-center w-full bg-nm_peach px-[12px] py-[16px] rounded-[16px] border-[1px] border-solid border-nm_black box-border max-h-[130px]"
        style={{ padding: `16px ${timerContainerHorPadding}px`, ...(bg_color && { backgroundColor: bg_color }) }}
      >
        {
          <>
            {<div className="h-[30px] flex justify-center items-center">{!isWebAppConfigLoading && content}</div>}
            <div
              className="flex justify-center items-center leading-[120%] font-bold tracking-[10%] text-nm_flamingo w-full mt-[5px]"
              style={{ fontSize: fontSize, ...(color && { color: color }), textShadow: '-1px 1px 0 #000, 1px 1px 0 #000,1px -1px 0 #000,-1px -1px 0 #000' }}
            >
              {renderTimer(totalHours, unitWidth, numOfHourDigits, isWebAppConfigLoading, true)}
              {renderTimer(getTimerCharacter('mins'), unitWidth, 2, isWebAppConfigLoading, true)}
              {renderTimer(getTimerCharacter('secs'), unitWidth, 2, isWebAppConfigLoading, false)}
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default BogoCountdownTimer;
