import { Box, Button } from '@mui/material';

import { isNonEmptyString } from '@Utils/checks';
import BottomSheet from '@Components/BottomSheet';

import styles from './styles';

interface GenericBottomSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  image?: React.ReactNode;
  title: string;
  description: string;
  buttonText?: string;
  handleButtonPress?: () => void;
}

const GenericBottomSheet = ({
  open,
  setOpen,
  image,
  title,
  description,
  buttonText,
  handleButtonPress
}: GenericBottomSheetProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  const onButtonClick = () => {
    if (handleButtonPress) handleButtonPress();
  };

  return (
    <BottomSheet open={open} handleClose={handleClose}>
      <Box p={2} pt={3}>
        {!!image && <Box mb={0.5}>{image}</Box>}
        <Box sx={styles.title}>{title}</Box>
        <Box sx={styles.description} mt={1}>
          {description}
        </Box>
        {isNonEmptyString(buttonText) && (
          <Box mt={5}>
            <Button variant='contained' sx={styles.buttonStyle} onClick={onButtonClick}>
              {buttonText}
            </Button>
          </Box>
        )}
      </Box>
    </BottomSheet>
  );
};

export default GenericBottomSheet;
