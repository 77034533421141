import useTranslation from '@Utils/useTranslation';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@Store/store';
import { CSSProperties, useCallback } from 'react';
import { ROUTES } from '@Constants/routes';
import styles from './styles';
import { isNonEmptyObject } from '@Utils/checks';

const Hoarding = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { deliveryPolicy } = useAppSelector((state) => state.home);
  const { deliveryInfo } = deliveryPolicy;
  const { is_visible: deliveryDetailsVisible, cod, shipping, return: returnable } = deliveryInfo ?? {};
  const isNoReturn = !returnable?.is_not_returnable ?? false;

  const onValuePropClick = useCallback(() => {
    navigate(ROUTES.RETURN_POLICY);
  }, [navigate]);

  return (
    deliveryDetailsVisible && (
      <div onClick={onValuePropClick} style={styles.rootContainer}>
        <div style={styles.root}>
          {returnable && isNonEmptyObject(returnable) && (
            <>
              <div style={styles.contentWrapper as CSSProperties}>
                <div style={styles.content as CSSProperties}>{returnable.title ?? (isNoReturn ? translate('no_returns') : translate('easy_returns'))}</div>
                <div style={styles.contentBody as CSSProperties}>{returnable.description ?? (isNoReturn ? translate('available') : translate('pick_up'))}</div>
              </div>
              <div style={styles.seperator as CSSProperties}></div>
            </>
          )}
          {shipping && isNonEmptyObject(shipping) && (
            <>
              <div style={styles.contentWrapper as CSSProperties}>
                <div style={styles.content as CSSProperties}>{shipping.title ?? translate('free_shipping')}</div>
                <div style={styles.contentBody as CSSProperties}>{shipping.description ?? translate('above_799')}</div>
              </div>
              <div style={styles.seperator}></div>
            </>
          )}
          {cod && isNonEmptyObject(cod) && (
            <div style={styles.contentWrapper as CSSProperties}>
              <div style={styles.content as CSSProperties}>{cod?.title ?? translate('freeCOD')}</div>
              <div style={styles.contentBody as CSSProperties}>{cod?.description ?? translate('free_cod_orders')}</div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Hoarding;
