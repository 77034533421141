import useTranslation from '@Utils/useTranslation';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Link from 'next/link';
import Image from 'next/image';

import { useAppDispatch, useAppSelector, RootState } from '@Store/store';
import { ROUTES } from '@Constants/routes';
import { ClickEvent } from '@Components/CustomCarousel/types';
import { HomePageSwiperJSCarousel, homePageCarouselFeedType } from '@Components/HomePageSwiperJSCarousel';
import { FeaturedSectionCarousel } from '@Components/FeaturedSectionCarousel';
import ReferIcon from '@assets/images/Refer.svg';
import CurrencyIcon from '@assets/icons/CurrencyIcon.svg';
import InstaIcon from '@assets/icons/Instagram.svg';
import MessageHeader from '@assets/icons/MessageHeader.svg';
import ShareNow from '@assets/icons/ShareNow.svg';
import { DescriptionTexts, NEWME_INSTGRAM, TitleBarTexts } from '@Constants/common';
import { setShowReferralBottomSheet } from '@Containers/Login/loginSlice';
import { generateTrackEventMetadata, getTrackingEventObject, useLoggedIn } from '@Utils/common';
import { DescriptionText, TitleBarText } from '@Components/index';
import { getHoardingURL } from '@Utils/common';
import Hoarding from '@Components/Hoarding';
import useLoginNavigation from '@Hooks/useLoginNavigation';
// import ReferAndEarnButton from '@Containers/ReferAndEarnButton';
import AppBar from '@Components/AppBar';
import SeoFooter from '@Components/SeoFooter';
import ProductCardListings from '@Components/ProductCardsListing';
import ReferralBottomSheet from '@Components/GenericBottomSheet';
import BogoCountdownTimer from '@Components/BogoCountdownTimer';
import { HomePageCarousalApiData } from './types';
import styles from './styles';
import { DEFAULT_MAX_PRICE, DEFAULT_MIN_PRICE } from '@Containers/Shop/Components/FilterControlsV2';
import { isNonEmptyArray, isNonEmptyObject, isNonEmptyString } from '@Utils/checks';
import useViewTracker from '@Hooks/useViewTracker';
import { handleAppDownloadClick } from '@Components/AppBanner';
import { HomePageCarousel } from '@Reducers/HomePageCarousel';
import HomePageReactSimplyCarousel from '@Components/HomePageReactSimplyCarousel';

const ContentWrapper = ({ children, type, track, handleSectionViewEvents = () => {}, data }) => {
  const { ref, inView } = useViewTracker({ handleIntersection: handleSectionViewEvents });
  const [viewedOnce, setViewedOnce] = useState(false);

  useEffect(() => {
    if (inView && !viewedOnce && isNonEmptyArray(data)) {
      handleTrackingOfHomePage({ track: track, id: `${isNonEmptyString(type) ? type?.replace('_', '-') : type}-carousel-${0}`, idx: 0, type: 'scroll', carouselData: data, feedType: (type === 'top_banners' ? homePageCarouselFeedType : `${type}-carousel`) });
      setViewedOnce(true);
    }
  }, [inView]);

  return <div ref={ref}>{children}</div>;
};

const parseCarouselData = (data) => {
  if (!isNonEmptyArray(data)) return data;

  const parsedCarousalData = data?.map((item: HomePageCarousalApiData) => {
    const { image_url, click_event, track_data } = item ?? {},
      { event_type, params } = click_event ?? {};

    return {
      imageUrl: image_url,
      clickEvent: {
        eventType: event_type,
        params: params
      },
      track_data
    };
  });

  return parsedCarousalData;
};

export const handleTrackingOfHomePage = ({ track, id, idx, type = 'scroll', feedType = null, carouselData = {}, metadata = {} }) => {
  if (!id || idx < 0) return;

  const { imageUrl, track_data, clickEvent } = carouselData?.[idx] ?? {},
    metaData = isNonEmptyObject(carouselData) ? { ...generateTrackEventMetadata({ imageUrl, clickEvent }), ...(track_data ?? {}) } : {};

  let feed_type = location.pathname === '/' ? 'Home' : location.pathname.split('/')[0];

  track(getTrackingEventObject(idx, feedType ?? feed_type, type, id, location.pathname, { meta: { ...metaData, ...(metadata && metadata) } }));
};

const Home = ({ homepageData = [], TrackerContext, bogoTimeLeft, bogoMetaData, isWebAppConfigLoading }) => {
  const { t: translate } = useTranslation();
  const track = useContext(TrackerContext);
  const navigate = useNavigate();
  const { navigateAfterLogin } = useLoginNavigation();
  const dispatch = useAppDispatch();
  const { isUserLoggedIn } = useLoggedIn();
  const router = useRouter();
  const { showReferralBottomSheet } = useAppSelector((state) => state.login);
  const { app_config_data: appConfigData } = useAppSelector((state: RootState) => state.layout);
  const homePageData = homepageData ?? {};
  const navigateToReferAndEarn = () => navigateAfterLogin(ROUTES.REFERANDEARN);

  const onBannerPress = (clickEvent: ClickEvent) => (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { category, sub_category, sort_by, tag, minPrice, maxPrice, sizes } = clickEvent?.params || {};
    const navigationParams = {
      product_cat: category || '',
      subCategory: sub_category || '',
      orderby: sort_by || '',
      tag: tag || '',
      ...(minPrice || maxPrice ? { price: `${minPrice ?? DEFAULT_MIN_PRICE},${maxPrice ?? DEFAULT_MAX_PRICE}` } : {}),
      ...(sizes && { size: isNonEmptyArray(sizes) ? sizes.join(',') : sizes })
    };

    if (tag) navigate(`/collection/${tag}?${createSearchParams(navigationParams)}&p=1`);
    else if (sub_category || category) navigate(`/womens-collection/${sub_category || category}?${createSearchParams(navigationParams)}&p=1`);
    else navigate(`/shop?${createSearchParams(navigationParams)}&p=1`);
  };

  const renderMessageHeader = (title: string) => (
    <div style={styles.messageHeader.root}>
      <div style={styles.messageHeader.headerWrapper}>
        <MessageHeader style={styles.messageHeader.icon} />
        <div style={styles.messageHeader.textWrapper}> {title} </div>
        <MessageHeader style={styles.messageHeader.icon} />
      </div>
    </div>
  );

  const renderTopBanners = (data: any) => {
    return (
      <>
      <div>
        {/* <HomePageSwiperJSCarousel
          carouselData={data}
        /> */}
        <HomePageReactSimplyCarousel carouselData={data} />
      </div>
      {bogoTimeLeft && <BogoCountdownTimer bogoSaleTimeLeft={bogoTimeLeft} bogoMetaData={bogoMetaData} isWebAppConfigLoading={isWebAppConfigLoading}/>}
    </>
    );
  };

  const renderValueProp = () => <Hoarding />;

  const renderTopHoarding = (data: Record<string, Record<string, string>>) => {
    const hoardings = data ?? {};

    const [url, redirect] = getHoardingURL(hoardings);

    return (
      <div>
        {url && (
          <Link href={redirect ?? ''} target="_blank" style={redirect ? styles.disabledLinkStyle : styles.linkStyle}>
            <img style={styles.imageStyle} src={url} alt="d" />
          </Link>
        )}
      </div>
    );
  };

  // const renderProductsSection = (data: any) => (
  //   <div style={styles.products.root}>
  //     <img
  //       alt=''
  //       style={styles.bannerImage}
  //       src={data?.poster?.image_url}
  //       onClick={onBannerPress(data?.poster?.click_event)}
  //     />
  //     <ProductCardListings
  //       cardData={data?.product_crousel?.products ?? []}
  //       onViewAllPress={()=>onBannerPress(data?.product_crousel.view_all_click_event)}
  //       linkData={data?.product_crousel.view_all_click_event}
  //     />
  //   </div>
  // );

  // function onIntersectBanner(data){
  //     let name = data?.poster?.click_event?.params?.tag || data?.poster?.image_url.split('/')[data?.poster?.image_url.split('/').length-1].split('.')[0]
  //     let id = `home-product-section-hero-${name}`
  //     let idx=-1
  //     handleTrackingOfHomePage(id, idx,'scroll')
  // }

  const handleProductListingOnPressEvents = (data, type: string) => {
    if (isNonEmptyObject(data)) {
      const { track_data } = data ?? {};
      const { vertical_pos } = track_data ?? {};
      handleTrackingOfHomePage({ track: track, id: `product-listing-poster-${vertical_pos ?? ''}`, idx: vertical_pos, type: type, metadata: track_data });
    }
  };

  const renderPosterSection = (data: any) => (
    <div style={styles.products.root as CSSProperties}>
      <img
        alt=""
        style={styles.bannerImage}
        src={data?.poster?.image_url}
        onClick={(e) => {
          onBannerPress(data?.poster?.click_event)(e);
          handleProductListingOnPressEvents(data?.poster, 'click');
        }}
        loading="lazy"
      />
    </div>
  );

  const renderProductsSection = (data: any, idx: any) => (
    <div style={styles.products.root as CSSProperties}>
      <ProductCardListings
        cardData={data?.product_crousel?.products ?? []}
        onViewAllPress={(e) => {
          onBannerPress(data?.product_crousel?.view_all_click_event)(e);
          handleProductListingOnPressEvents(data?.product_crousel?.view_all_click_event, 'view-all-click');
        }}
        linkData={data?.product_crousel.view_all_click_event}
        idx={idx}
      />
    </div>
  );

  const renderFeaturedSection = (data: any, title: string) => {
    return (
      <div style={styles.featuredSection.root}>
        <h2 style={styles.featuredSection.heading}>{title || 'Featured this week'}</h2>
        <div>
          <FeaturedSectionCarousel carouselData={data} />
        </div>
      </div>
    );
  };

  const renderReferSection = () => (
    <div style={styles.refer.root as CSSProperties}>
      <Link href={NEWME_INSTGRAM ?? ''} style={styles.refer.instaSection} target="_blank">
        <InstaIcon style={styles.refer.instaIcon} />
        <div style={styles.refer.instaText1}>{translate('tag_us.1')}</div>
        <div style={styles.refer.instaText2}>{translate('tag_us.2')}</div>
      </Link>
      <div style={styles.refer.referSection as CSSProperties}>
        <div style={styles.refer.title}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {translate('refer_friend_title')}
            <CurrencyIcon style={{ height: '14px', width: '10px', marginLeft: '6px' }} />
            <div>500</div>
          </div>
        </div>
        <div style={styles.refer.subtitle}>
          {translate('refer_newme.1')}{' '}
          <span style={styles.refer.amount}>
            <CurrencyIcon style={{ height: '11px', width: '10px', marginLeft: '6px' }} />
            <span>500</span>
          </span>
          {translate('refer_newme.3')}
        </div>
        <div style={styles.refer.contentWrapper as CSSProperties}>
          <ReferIcon />
          <div style={styles.refer.buttonWrapper as CSSProperties}>
            <button
              className="customButton customButton--noScale customButton--transparent"
              style={styles.refer.button as CSSProperties}
              onClick={navigateToReferAndEarn}
            >
              {translate('refer_now')}
            </button>
          </div>
        </div>
      </div>
      <div style={styles.refer.footer as CSSProperties}>
        <div style={styles.refer.iconContainer}>
          <span> {translate('made_with')}</span>
          <span style={styles.refer.iconWrapper}>
            <Image priority src="/assets/icons/WishlistActive.svg" height={17} width={25} alt="Follow us on Twitter" />
          </span>
          <span> {translate('in_india')}</span>
        </div>
      </div>
    </div>
  );

  const renderDownloadSection = () => {
    const handleDownloadClick = () => handleAppDownloadClick(track, router.pathname, 'footer');

    return (<div style={styles.downloadSection.root}>
      <div style={styles.downloadSection.heading}>Download NEWME App</div>
      <div style={styles.downloadSection.ctas}>
        <Link style={{ ...styles.downloadSection.links, marginRight: '8px' }} href={'https://newmeapp.app.link/downloadapp_home_bottom'} target="_blank">
          <Image
            // priority
            src="/assets/images/playStore.svg"
            width={127}
            height={40}
            alt="Download newme app"
            onClick={handleDownloadClick}
          />
        </Link>
        <Link style={styles.downloadSection.links} href={'https://newmeapp.app.link/downloadapp_home_bottom'} target="_blank">
          <Image
            // priority
            src="/assets/images/appStore.svg"
            width={129}
            height={40}
            alt="Download newme app"
            onClick={handleDownloadClick}
          />
        </Link>
      </div>
    </div>)
  };

  const getContents = ({ type, title, data }: any, idx: any) => {
    switch (type) {
      case 'message_header':
        return renderMessageHeader(data?.message);
      case 'top_banners':
        return renderTopBanners(data);
      case 'top_web_hoardings':
        return renderTopHoarding(data);
      case 'value-prop-section':
        if (appConfigData?.data?.is_bogo_live_for_user) {
          return;
        }
        return renderValueProp();
      case 'poster':
        return renderPosterSection(data);
      case 'product_section':
        return renderProductsSection(data, idx);
      case 'refer-section':
        return renderReferSection();
      case 'featured-section':
        return renderFeaturedSection(data, title);
    }
  };

  const renderUI = ({ type, title, data, track_data }: any, idx: any) => {
    const { vertical_pos } = track_data ?? {};
    const parsedData = parseCarouselData(data);
    const handleSectionViewEvents = () => {
      handleTrackingOfHomePage({ track: track, id: `home-${isNonEmptyString(type) ? type?.replace('_', '-') : type}`, idx: vertical_pos, type: 'view', metadata: track_data });

      if (type === 'poster' && isNonEmptyObject(parsedData?.poster)) {
        const { track_data } = parsedData?.poster ?? {};
        const { vertical_pos } = track_data ?? {};
        handleTrackingOfHomePage({ track: track, id: `product-listing-poster-${vertical_pos ?? ''}`, idx: vertical_pos, type: 'view', metadata: track_data });
      }
    };

    return (
      <ContentWrapper type={type} track={track} handleSectionViewEvents={handleSectionViewEvents} data={data}>
        {getContents({ type: type, title: title, data: parsedData }, idx)}
      </ContentWrapper>
    );
  };

  const handleShowReferralBottomSheet = (show: boolean) => {
    dispatch(setShowReferralBottomSheet(show));
  };

  const handleReferralBottomSheetButtonPress = () => {
    handleShowReferralBottomSheet(false);
    navigate(ROUTES.REFERANDEARN);
  };

  return (
    <div style={styles.root as CSSProperties}>
      <TitleBarText text={TitleBarTexts.HOME} />
      <DescriptionText text={DescriptionTexts.HOME} />
      <AppBar bogoSaleTimeLeft={bogoTimeLeft} bogoMetaData={bogoMetaData} isWebAppConfigLoading={isWebAppConfigLoading} />
      <div>
        {
          <div style={styles.contentWrapper as CSSProperties}>
            {homePageData?.map((item: any, index: number) => (
              <div key={index}>{renderUI(item, index)}</div>
            ))}
            <div key="download">{renderDownloadSection()}</div>
            <SeoFooter seoData={{}}/>
          </div>
        }
      </div>
      {/* <ReferAndEarnButton /> */}
      <ReferralBottomSheet
        open={showReferralBottomSheet && isUserLoggedIn}
        setOpen={handleShowReferralBottomSheet}
        image={<ShareNow />}
        title={translate('referral_bottomsheet_title')}
        description={translate('referral_bottomsheet_description')}
        buttonText={translate('referral_bottomsheet_button') || ''}
        handleButtonPress={handleReferralBottomSheetButtonPress}
      />
    </div>
  );
};

export default Home;
