import { FC, useEffect, useRef, useContext, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { HOMEPAGE_CAROUSEL_AUTOPLAY_DELAY } from '@Constants/carousel';
import { ROUTES } from '@Constants/routes';
import { ClickEvent } from '@Components/CustomCarousel/types';
import { useAppDispatch } from '@Store/store';
import { resetShopScroll } from '@Containers/ScrollRestoration/scrollSlice';
import {Pagination, Autoplay} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/pagination';
import 'swiper/css';
import { colors } from '@Constants/common';

import { useInView } from 'react-intersection-observer';
import { CarouselEventTypes, HomePageCarouselProps } from './types';
import { TrackerContext } from '_app'
import { DEFAULT_MAX_PRICE, DEFAULT_MIN_PRICE } from '@Containers/Shop/Components/FilterControlsV2';
import { handleTrackingOfHomePage } from '@Containers/Home';
import { RootState, useAppSelector } from '@Store/store';
import { isNonEmptyArray } from '@Utils/checks';

export const homePageCarouselFeedType = 'Home Page Carousel';

const uiConfig = {
  default:{
    spaceBetween: 16,
    slidesPerView: 1.1,
    // paddingLeft: 16,
    top:0,
    left:16,
    bottom:16,
    right:0,
  },
  'black-friday':{
    spaceBetween: 16,
    slidesPerView: 1,
    top:16,
    left:16,
    bottom:16,
    right:16,
    // paddingLeft: 0,
  }
}

export const HomePageSwiperJSCarousel: FC<HomePageCarouselProps> = ({ carouselData, disableClick = false, trackSlide }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const track = useContext(TrackerContext);
  const { app_config_data: appConfigData } = useAppSelector((state: RootState) => state.layout);
  const [themeKey,setThemeKey] = useState('default')

  useEffect(()=>{
    if(appConfigData?.data){
      setThemeKey(appConfigData?.data?.theme)
    }
  },[appConfigData])


  const onBannerPress = (clickEvent: ClickEvent, idx:any) => async () => {
    if (disableClick) return;

    handleTrackingOfHomePage({track: track,id:`home-page-carousel-${idx}-click` , idx: idx, type:'click', carouselData: carouselData, feedType: homePageCarouselFeedType});


    const { category, sub_category, sort_by, tag, slug, link, minPrice, maxPrice, sizes } = clickEvent?.params || {};

    const navigationParams = {
      product_cat: category || '',
      subCategory: sub_category || '',
      orderby: sort_by || '',
      tag: tag || '',
      ...(minPrice || maxPrice ? {price: `${minPrice ?? DEFAULT_MIN_PRICE},${maxPrice ?? DEFAULT_MAX_PRICE}`}: {}),
      ...(sizes && { size: isNonEmptyArray(sizes) ? sizes.join(',') : sizes })
    };

    const productListingNavigation = tag
      ? `/collection/${tag}/?${createSearchParams(navigationParams)}`
      : `${ROUTES.SHOP}?${createSearchParams(navigationParams)}`;

    switch (clickEvent?.eventType) {
      case CarouselEventTypes.PRODUCT_LISTING:
        {
          dispatch(resetShopScroll());
          navigate(productListingNavigation);
        }
        break;
      case CarouselEventTypes.PRODUCT_DETAILS:
        navigate(`/product/${slug}/`);
        break;
      case CarouselEventTypes.REFER_AND_EARN:
        navigate(ROUTES.REFERANDEARN);
        break;
      case 'REDIRECT':
        window.location.href = link ?? 'https://newme.asia/';
        break;
      default:
        break;
    }
  };

  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 })
  const swiperRef = useRef<any>(null)
  useEffect(()=>{
    if(inView){
      let activeSlide = swiperRef.current?.swiper?.activeIndex
    }
  },[inView])

  const handleChange =  e => {
    if(!inView) return;

    const idx = e.realIndex;  
    handleTrackingOfHomePage({track: track,id:`home-page-carousel-${idx}` , idx: idx, type:'scroll', carouselData: carouselData, feedType: homePageCarouselFeedType});
  }

  return (
    <div
      ref={ref}
      style={{
        width:'100%',
        maxWidth:'100%',
        paddingTop:'16px',
        backgroundColor: '#fff',
        paddingBottom: appConfigData?.data?.is_bogo_live_for_user ? '16px' : '0px',
      }}
    >
      <Swiper
        ref={swiperRef}
        spaceBetween={uiConfig?.[themeKey]?.spaceBetween}
        slidesPerView={uiConfig?.[themeKey]?.slidesPerView}
        loop={true}
        onSlideChange={handleChange}
        autoplay={{delay: HOMEPAGE_CAROUSEL_AUTOPLAY_DELAY}}
        style={{
          "--swiper-pagination-color": colors.PRIMARY,
          "--swiper-pagination-bullet-inactive-color": colors.GREYED_OUT,
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-pagination-bullet-horizontal-gap": "4px",
          "--swiper-pagination-bottom": "0px",
          "--swiper-pagination-bullet-inactive-opacity": ".85",
          // paddingBottom: "16px",
          // paddingLeft: `${uiConfig?.[themeKey]?.paddingLeft}px`,
          padding: `${uiConfig?.[themeKey]?.top}px ${uiConfig?.[themeKey]?.right}px ${uiConfig?.[themeKey]?.bottom}px ${uiConfig?.[themeKey]?.left}px`
        }}
        modules={[Pagination, Autoplay]}
        pagination={{ clickable: true, dynamicBullets: true }}
      >
        {carouselData.map((item, index) => (
          <SwiperSlide  key={index} onClick={item?.clickEvent ? onBannerPress(item?.clickEvent,index) : undefined}>
            <img
              style={{ objectFit: 'contain', objectPosition: 'bottom', width: '100%', borderRadius:'16px'}}
              src={item?.imageUrl}
              alt='carousel-images'
            />
          </SwiperSlide>
        ))}
        
      </Swiper>
    </div>
  );
};
