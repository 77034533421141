import { colors } from '@Constants/common';

const styles = {
  title: {
    fontSize: '14px',
    color: colors.FONT_SECONDARY,
    fontWeight: 600
  },
  description: {
    color: colors.DIM_GRAY,
    fontSize: '14px'
  },
  buttonStyle: {
    height: 48,
    fontWeight: 600,
    textTransform: 'none',
    width: '100%',
    color: colors.WHITE
  }
};

export default styles;
