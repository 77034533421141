import { colors } from '@Constants/colors';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    // background: 'linear-gradient(180deg, rgba(189, 242, 210, 1) 30%, rgba(223, 251, 234, 0.1)), linear-gradient(275deg, rgba(223,250,214,255) , rgba(223, 251, 234, 0.1) 20%), linear-gradient(90deg, rgba(125, 227, 108, 0.3), rgba(223, 251, 234, 0.9) 40%)',
    backgroundColor: colors.LIGHT_GREEN_2,
    paddingTop: '12px',
    paddingBottom: '12px',
    cursor: 'pointer',
    alignItems: 'center',
    width: '90%',
    margin: 'auto',
    borderRadius: '20px'
  },
  rootContainer: {
    backgroundColor: colors.WHITE,
    padding: '20px 0px'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '30%',
    padding: '0px 5px'
  },
  content: {
    color: colors.PRIMARY_TEXT,
    fontWeight: 700,
    fontSize: '12px',
    marginTop: '2px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  contentBody: {
    color: colors.PRIMARY_TEXT,
    fontWeight: 400,
    fontSize: '9px',
    marginTop: '2px',
    lineHeight: '13.5px',
    textAlign: 'center',
  },
  seperator: {
    height: '40px',
    width: '1px',
    backgroundColor: colors.PRIMARY_GRAY
  }
};

export default styles;
