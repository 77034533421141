import { CarouselDataItem } from '@Components/CustomCarousel/types';
import { trackEventMetadata } from '@Containers/Home/types';

export interface HomePageCarouselProps {
  carouselData: Array<CarouselDataItem>;
  disableClick?: boolean;
  trackSlide: (track: any, id: any, idx:Number, type:any, metadata: trackEventMetadata) => void;
}

export enum CarouselEventTypes {
  PRODUCT_LISTING = 'PRODUCT_LISTING',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
  REFER_AND_EARN = 'REFER_AND_EARN'
}
