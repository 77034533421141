import { FC, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { ROUTES } from '@Constants/routes';
import { handleTrackingOfHomePage } from '@Containers/Home';
import { ClickEvent, CarouselDataItem } from '@Components/CustomCarousel/types';
import { useAppDispatch } from '@Store/store';
import { resetShopScroll } from '@Containers/ScrollRestoration/scrollSlice';
import { DEFAULT_MAX_PRICE, DEFAULT_MIN_PRICE } from '@Containers/Shop/Components/FilterControlsV2';
import { TrackerContext } from '_app';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { CarouselEventTypes, FeaturedSectionProps } from './types';
import { isNonEmptyArray } from '@Utils/checks';

export const FeaturedSectionCarousel: FC<FeaturedSectionProps> = ({ carouselData, disableClick = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const track = useContext(TrackerContext);

  const onBannerPress = (clickEvent: ClickEvent, idx:any) => () => {
    if (disableClick) return;

    const id = `featured-section-carousel-${idx}-click`;
    handleTrackingOfHomePage({track: track, id: id, idx: idx, type:'click', carouselData: carouselData, feedType: id});

    const { category, sub_category, sort_by, tag, slug, link, minPrice, maxPrice, sizes } = clickEvent?.params || {};

    const navigationParams = {
      product_cat: category || '',
      subCategory: sub_category || '',
      orderby: sort_by || '',
      tag: tag || '',
      ...(minPrice || maxPrice ? {price: `${minPrice ?? DEFAULT_MIN_PRICE},${maxPrice ?? DEFAULT_MAX_PRICE}`}: {}),
      ...(sizes && { size: isNonEmptyArray(sizes) ? sizes.join(',') : sizes })
    };

    const productListingNavigation = tag
      ? `/collection/${tag}/?${createSearchParams(navigationParams)}`
      : `${ROUTES.SHOP}?${createSearchParams(navigationParams)}`;

    switch (clickEvent?.eventType) {
      case CarouselEventTypes.PRODUCT_LISTING:
        {
          dispatch(resetShopScroll());
          navigate(productListingNavigation);
        }
        break;
      case CarouselEventTypes.PRODUCT_DETAILS:
        navigate(`/product/${slug}/`);
        break;
      case CarouselEventTypes.REFER_AND_EARN:
        navigate(ROUTES.REFERANDEARN);
        break;
      case 'REDIRECT':
        window.location.href = link ?? 'https://newme.asia/';
        break;
      default:
        break;
    }
  };

  const handleChange =  e => {
    if(!inView) return;

    const idx = e.realIndex,
          id = `featured-section-carousel-${idx}`;
    handleTrackingOfHomePage({track: track, id: id, idx: idx, type:'scroll', carouselData: carouselData, feedType: id});
  }

  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 })

  return (
    <div
      ref={ref}
      style={{
        width:'100%',
        maxWidth:'100%',
        paddingTop:'16px'
      }}
    >
      <Swiper
        spaceBetween={16}
        slidesPerView={1.7}
        onSlideChange={handleChange}
        style={{
          paddingLeft: "16px",
        }}
      >
        {carouselData.map((item, index) => (
          <SwiperSlide key={index} onClick={item?.clickEvent ? onBannerPress(item?.clickEvent, index) : undefined}>
            <img
              style={{ objectFit: 'contain', objectPosition: 'bottom', width: '100%', borderRadius:'16px'}}
              src={item?.imageUrl}
              alt='carousel-images'
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};