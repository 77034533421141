import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const useViewTracker = ({ handleIntersection = () => {} }) => {
  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 });

  useEffect(() => {
    if (inView) handleIntersection?.();
  }, [inView]);

  return { ref: ref, inView: inView };
};

export default useViewTracker;
