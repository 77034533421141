import { FC, useEffect, memo, useRef, useContext, useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { HOMEPAGE_CAROUSEL_AUTOPLAY_DELAY } from '@Constants/carousel';
import { ROUTES } from '@Constants/routes';
import { ClickEvent } from '@Components/CustomCarousel/types';
import { useAppDispatch } from '@Store/store';
import { resetShopScroll } from '@Containers/ScrollRestoration/scrollSlice';
import ChevronBackward from '@assets/icons/chevronBackward.svg';
import ChevronForward from '@assets/icons/chevronForward.svg';

import { colors } from '@Constants/common';

import { useInView } from 'react-intersection-observer';
import { CarouselEventTypes, HomePageCarouselProps } from './types';
import { TrackerContext } from '_app'
import { DEFAULT_MAX_PRICE, DEFAULT_MIN_PRICE } from '@Containers/Shop/Components/FilterControlsV2';
import { handleTrackingOfHomePage } from '@Containers/Home';
import { RootState, useAppSelector } from '@Store/store';
import CustomCarouselDotsV2 from '@Reducers/CustomCarouselDotsV2';
import styleModule from './styles.module.css';
import CustomCarouselDots from '@Reducers/CustomCarouselDots';

export const homePageCarouselFeedType = 'Home Page Carousel';


export const HomePageReactSimplyCarousel: FC<HomePageCarouselProps> = ({ carouselData, disableClick = false, trackSlide }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const track = useContext(TrackerContext);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const { app_config_data: appConfigData } = useAppSelector((state: RootState) => state.layout);
  const [themeKey, setThemeKey] = useState('default')

  useEffect(()=>{
    if(appConfigData?.data){
      setThemeKey(appConfigData?.data?.theme)
    }
  },[appConfigData])

  const onBannerPress = (clickEvent: ClickEvent, idx:any) => async () => {
    if (disableClick) return;

    handleTrackingOfHomePage({track: track,id:`home-page-carousel-${idx}-click` , idx: idx, type:'click', carouselData: carouselData, feedType: homePageCarouselFeedType});


    const { category, sub_category, sort_by, tag, slug, link, minPrice, maxPrice } = clickEvent?.params || {};

    const navigationParams = {
      product_cat: category || '',
      subCategory: sub_category || '',
      orderby: sort_by || '',
      tag: tag || '',
      ...(minPrice || maxPrice ? {price: `${minPrice ?? DEFAULT_MIN_PRICE},${maxPrice ?? DEFAULT_MAX_PRICE}`}: {})
    };

    const productListingNavigation = tag
      ? `/collection/${tag}/?${createSearchParams(navigationParams)}`
      : `${ROUTES.SHOP}?${createSearchParams(navigationParams)}`;

    switch (clickEvent?.eventType) {
      case CarouselEventTypes.PRODUCT_LISTING:
        {
          dispatch(resetShopScroll());
          navigate(productListingNavigation);
        }
        break;
      case CarouselEventTypes.PRODUCT_DETAILS:
        navigate(`/product/${slug}/`);
        break;
      case CarouselEventTypes.REFER_AND_EARN:
        navigate(ROUTES.REFERANDEARN);
        break;
      case 'REDIRECT':
        window.location.href = link ?? 'https://newme.asia/';
        break;
      default:
        break;
    }
  };

  const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: false, trackVisibility: false, delay: 0 })

  const handleChange =  idx => {
    if(!inView) return;

    handleTrackingOfHomePage({track: track,id:`home-page-carousel-${idx}` , idx: idx, type:'scroll', carouselData: carouselData, feedType: homePageCarouselFeedType});
  }

  const imgSrcs = carouselData?.map(data => data?.imageUrl)

  return (
    <div
      ref={ref}
      className={`w-full max-w-full pt-4 bg-nm_white ${appConfigData?.data?.is_bogo_live_for_user ? 'pb-4' : 'pb-0'}`}
    >
      <ReactSimplyCarousel
          infinite
          autoplay={true}
          autoplayDelay={HOMEPAGE_CAROUSEL_AUTOPLAY_DELAY}
          touchSwipeTreshold={10}
          mouseSwipeTreshold={1}
          activeSlideIndex={activeSlideIndex}
          onRequestChange={setActiveSlideIndex}
          itemsToShow={1.2}
          itemsToScroll={1}
          forwardBtnProps={{
            className: `${styleModule.carouselNavbuttonsContainer} right-[24px]`,
            children: <div className={styleModule.carouselNavbuttons}>
              <ChevronForward style={{paddingLeft: '2px'}} />
            </div>
          }}
          backwardBtnProps={{
            className: `${styleModule.carouselNavbuttonsContainer} left-[24px]`,
            children: <div className={`${styleModule.carouselNavbuttons}`}>
              <ChevronBackward style={{paddingRight: '2px'}} />
            </div>
          }}
          speed={400}
          innerProps={{
            className: '!overflow-visible',
          }}
          containerProps={{
            className: `${styleModule.homepageCarousel} overflow-hidden`
          }}
          onAfterChange={handleChange}
        >
          {carouselData?.map((item, idx) => (
            <div className={`pr-4`}>
              <img
              key={idx}
              draggable={false}
              style={{width: 'calc(100vw - 32px)', maxWidth: '418px'}}
              className="h-[100%] object-cover rounded-[16px]"
              src={item?.imageUrl}
              loading={'eager'}
              onLoad={() => {}}
              onClick={item?.clickEvent ? onBannerPress(item?.clickEvent, idx) : undefined}
            />
            </div>
          ))}
        </ReactSimplyCarousel>

        <div className='relative h-[40px] '>
          {/* <CustomCarouselDotsV2 carouselContainerStyle={{paddingTop:'16px', paddingBottom: '32px'}} inActiveDotStyle={{backgroundColor: '#D7D7D7'}} activeSlideIndex={activeSlideIndex} setActiveSlideIndex={setActiveSlideIndex} data={imgSrcs} timeout={HOMEPAGE_CAROUSEL_AUTOPLAY_DELAY} inActiveDotStyle={{backgroundColor: 'black', width: '6px', height: '6px'}} totalDots={5} freezeDotIndex={2} activeDotStyle={{backgroundColor: 'black', width: '6px', height: '6px'}} activeDotBackgroundStyle={{backgroundColor: '#BABABA', height: '6px', borderRadius: '8px'}} inActiveDotBackgroundStyle={{width: '6px'}}/> */}
          <CustomCarouselDots inActiveDotStyle={{backgroundColor: '#D7D7D7'}} activeSlideIndex={activeSlideIndex} setActiveSlideIndex={setActiveSlideIndex} data={imgSrcs} />
        </div>
    </div>
  );
};

export default memo(HomePageReactSimplyCarousel);