import { colors } from '@Constants/colors';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100vw',
    maxWidth: '450px'
  },
  footerWrapper: {
    display: 'flex',
    bottom: 0,
    position: 'sticky'
  },
  bannerImage: {
    cursor: 'pointer'
  },
  contentWrapper: { display: 'flex', flexDirection: 'column', padding: '56px 0 70px 0' },
  messageHeader: {
    root: {
      height: '40px',
      padding: '8px',
      background: 'linear-gradient(to right bottom, #FDD8EA, #FEEDD3)'
    },
    headerWrapper: {
      backgroundColor: colors.SECONDARY,
      height: '40px',
      display: 'flex',
      justifyContent: 'space-around'
    },
    icon: {
      marginTop: '8px'
    },
    textWrapper: {
      marginTop: 1,
      fontWeight: 600,
      fontSize: '14px'
    }
  },
  topBanners: {
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      backgroundColor: colors.SECONDARY
    },
    contentWrapper: { display: 'flex' }
  },
  valueProp: {
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      background: 'linear-gradient(to right bottom, #DFFFF0, #FFFFFF)',
      paddingTop: '30px',
      paddingBottom: '30px',
      cursor: 'pointer'
    },
    contentWrapper: { display: 'flex', maxWidth: '95px' },
    content: {
      color: colors.PRIMARY_DARK,
      fontWeight: 500,
      fontSize: '12px',
      paddingLeft: 1,
      marginTop: '2px'
    }
  },
  products: {
    root: { display: 'flex', flexDirection: 'column' }
  },
  refer: {
    root: { display: 'flex', flexDirection: 'column' },
    instaSection: {
      height: '40px',
      background: 'linear-gradient(to right bottom, #EAFFF5, #EAFFF5)',
      display: 'flex',
      justifyContent: 'center',
      textDecoration: 'none'
    },
    instaIcon: { marginTop: '12px', marginRight: '5px' },
    instaText1: {
      marginTop: '13px',
      fontWeight: 500,
      fontSize: '12px',
      color: colors.DIM_GRAY
    },
    instaText2: {
      marginLeft: '2px',
      marginTop: '12px',
      fontWeight: 500,
      fontSize: '12px',
      color: colors.PRIMARY_DARK
    },
    referSection: {
      backgroundColor: colors.PRIMARY_LIGHTER,
      display: 'flex',
      flexDirection: 'column',
      height: '250px',
      position: 'relative',
      paddingTop: 4,
      paddingBottom: 0,
      paddingLeft: '16px',
      paddingRight: '16px'
    },
    title: {
      fontWeight: 500,
      fontSize: '18px',
      color: '#1F1F1F'
    },
    subtitle: {
      lineHeight: '20px',
      marginTop: '12px',
      fontWeight: 400,
      fontSize: '14px',
      color: colors.DIM_GRAY
    },
    amount: { color: '#1F1F1F' },
    contentWrapper: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    buttonWrapper: { position: 'absolute', left: 16 },
    button: {
      border: '1.5px solid #1D6B46',
      padding: '10px 20px',
      borderRadius: '8px',
      height: '48px',
      color: colors.PRIMARY_DARK,
      textTransform: 'none',
      fontSize: '14px',
      fontWeight: 600,
      cursor: 'pointer'
    },
    footer: {
      paddingTop: '15px',
      paddingBottom: '15px',
      background: 'linear-gradient(to right bottom, #D7FFEC, #D7FFEC)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    } as React.CSSProperties,
    iconWrapper: { marginTop: '5px' },
    icon: { height: '17px' }
  },
  featuredSection: {
    root: {
      justifyContent: 'space-around',
      backgroundColor: colors.WHITE,
      paddingBottom: '30px'
    },
    heading: {
      fontSize: '16px',
      padding: "0 16px",
      backgroundColor: colors.WHITE,
      lineHeight: '24px',
      margin: '0'
    }
  },
  imageStyle: { width: '100%' },
  linkStyle: { pointerEvents: 'none' },
  disabledLinkStyle: {},
  downloadSection:{
    root:{
      paddingTop: '34px',
      paddingBottom: '42px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    heading:{
      fontSize:'16px',
      fontWeight:600,
      lineHeight:'24px',
      color:colors.FONT_SECONDARY,
      marginBottom:'24px'
    },
    ctas:{
      display:'flex',
    },
    links:{
      border: '1px solid',
      borderColor: colors.DARK_GREEN_2,
      width:'44vw',
      height:'44px',
      backgroundColor:colors.WHITE,
      borderRadius:'6px',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
    }
  }
};

export default styles;
